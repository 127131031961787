import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import crypto from "../../Assets/Projects/crypto.png";
import courxel from "../../Assets/Projects/courxel.png";
import overstock from "../../Assets/Projects/overstock.png";



function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
      
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={crypto}
              isBlog={false}
              title="Crypto-Chart"
              description="Track crypto prices, visualize trends, and discover hot coins. Built with React.js, styled with Chakra UI, and powered by CoinGecko API. Choose Chart.js or Apache ECharts for interactive charts. 🚀"
              ghLink="https://github.com/AmaanHaider/crypto-chart"
              demoLink="https://crypto-chart-amaan.vercel.app/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={courxel}
              isBlog={false}
              title="CourXel"
              description="I've developed a dynamic Course Marketplace where users can both buy and sell courses. Leveraging React, Node.js, Express.js, and MongoDB, this platform empowers knowledge sharing. Payments are a breeze with Stripe integration. Explore, learn, and teach - it's all just a click away! 💡🚀"
                 ghLink="https://github.com/AmaanHaider/courxel"
              demoLink="https://courxel.vercel.app/"
            />
          </Col>
          
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={overstock}
              isBlog={false}
              title="Overstock-Clone"
              description="Overstock is tech driven online retailer that sells furniture, appliances, home decor, outdoor equipment and decor, sewing and craft items. Its inventory is liquidated merchandise from other retailers."   
               ghLink="https://github.com/AmaanHaider/overstock-clone"
              demoLink="https://overstockk.netlify.app/"
            />
          </Col>
       
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
