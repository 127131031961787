import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";
import { SiYourtraveldottv } from "react-icons/si";


function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            <br /> Ahoy there, <span className="purple">I'm Amaan Haider </span>, your coding companion with a
            twist! Armed with React.js, Node.js, Express.js, TypeScript, and a
            slightly excessive amount of caffeine, I navigate the digital seas
            like a tech-savvy pirate..           
            <br />
            <br />
            Think of me as the code jester. Bugs are my punchlines, and they
            can't resist a good one-liner. I've even considered taking them to
            open mic nights, but they're a tough crowd.

            <br/>
            <br/>
            Apart from coding, some other activities that I love to do!
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> Playing Sports
            </li>
            <li className="about-activity">
              <SiYourtraveldottv /> Travelling
            </li>
          </ul>
          <p style={{ color: "rgb(155 126 172)" }}></p>
          <footer className="blockquote-footer"></footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
